// src/components/PortfolioHelper.js
import React from 'react';

import './PortfolioHelper.css'; // Add styling for the grid here

const images = [
    '/images/fullimages/KLM.jpg',
    '/images/fullimages/LH747.jpg',
    '/images/fullimages/AC777YVR.jpg',
    '/images/fullimages/AC787YYZ.jpg',
    '/images/fullimages/AC787HOUSE.jpg',
];

const PortfolioHelper = () => {
  return (
    <div className="image-grid">
      {images.map((src, index) => (
        <div className="image-item" key={index}>
          <img src={src} alt={`Portfolio ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default PortfolioHelper;
