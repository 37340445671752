//Portfolio at './pages/Portfolio.js
import React from 'react';
import PortfolioHelper from '../components/PortfolioHelper'; // Import the helper component
import OtherHeader from '../components/OtherHeader';

import '../components/OtherHeader.css';
import './Portfolio.css';
import Footer from '../components/Footer';


const Portfolio = () => {
  return (
    <div className="portfolio-container">
          <OtherHeader /> {/* Place Navbar on top of the image */}
      <div className="portfolio-content">
        {/* Add your portfolio items here */}
        <PortfolioHelper /> {/* This will render the images in a two-column layout */}
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
