// webphotos3/src/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Header.css'

const Header = () => {
    // State to manage menu visibility on mobile
    const [menuOpen, setMenuOpen] = useState(false);

    // Toggle function for the menu
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };


  return (
    <nav className="header">
      <div className='bandN'>
        <div className="name">
          <Link to="/home">
            <span className="temporary">TEMPORARY</span>
            <span className="window">WINDOW</span>
          </Link>
        </div>
        <div className="menu-btn" onClick={toggleMenu}>
          ☰ {/* This represents the hamburger icon */}
        </div>
      </div>
      {/* Navigation links */}
      <ul className="navLinks">
        <li className="nav-item home"><Link to="/home">HOME</Link></li>
        <li><Link to="/portfolio">PORTFOLIO</Link></li>
        <li><a href="store">STORE</a></li>
        <li><Link to="/about">ABOUT</Link></li>
      </ul>

      <div className={`menu-overlay ${menuOpen ? 'active' : ''}`}>
        <Link to="/home" onClick={toggleMenu}>HOME</Link>
        <Link to="/portfolio" onClick={toggleMenu}>PORTFOLIO</Link>
        <a href="store" onClick={toggleMenu}>STORE</a>
        <Link to="/about" onClick={toggleMenu}>ABOUT</Link>
      </div>
    </nav>
  );
};

export default Header;
