import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';


import HomePage from './pages/HomePage'; // Import the HomePage component
import Portfolio from './pages/Portfolio'; // Import the HomePage component
import StorePage from './pages/StorePage';
import AboutPage from './pages/AboutPage';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} /> {/* Home route */}
          <Route path="/home" element={<HomePage />} /> {/* Home route, footer taken */}
          <Route path="/portfolio" element={<Portfolio />} /> {/* Portfolio route, footer taken */}
          <Route path="/store" element={<StorePage />} /> {/* Portfolio route, footer taken */}
          <Route path="/about" element={<AboutPage />} /> {/* About route, footer taken */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
