// webphotos3/src/OtherHeader.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './OtherHeader.css';

const OtherHeader = () => {
  // State to manage menu visibility on mobile
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle function for the menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="otherHeader">
      <div className='bandN'>
        <div className="otherName">
        <Link to="/home">
          <span className="otherTemporary">TEMPORARY</span>
          <span className="otherWindow">WINDOW</span>
        </Link>
          {/* Menu button for mobile */}
        </div>
        <div className="menu-btn" onClick={toggleMenu}>
          ☰ {/* This represents the hamburger icon */}
        </div>
      </div>
      

      {/* Navigation links */}
      <ul className={`otherNavLinks ${menuOpen ? 'mobile-active' : ''}`}>
        <li className="otherNav-item home"><Link to="/home">HOME</Link></li>
        <li><Link to="/portfolio">PORTFOLIO</Link></li>
        <li><a href="store">STORE</a></li>
        <li><Link to="/about">ABOUT</Link></li>
      </ul>

      <div className={`menu-overlay ${menuOpen ? 'active' : ''}`}>
        <Link to="/home" onClick={toggleMenu}>HOME</Link>
        <Link to="/portfolio" onClick={toggleMenu}>PORTFOLIO</Link>
        <a href="store" onClick={toggleMenu}>STORE</a>
        <Link to="/about" onClick={toggleMenu}>ABOUT</Link>
      </div>
    </nav>
  );
};

export default OtherHeader;
