import React from 'react';
import OtherHeader from '../components/OtherHeader';

import '../components/OtherHeader.css';
import './AboutPage.css'

import Footer from '../components/Footer';


const image = '/images/other/LILELMO.jpg';

const AboutPage = () => {
    return (
        <div className="aboutpage-container">
            <OtherHeader/>
            <div className='aboutpage-content'>
                <img src={image} alt='Elman in the snow' className="snowboy" />
                <p>I like planes</p>
            </div>
            <Footer />
        </div>



    );


};

export default AboutPage;