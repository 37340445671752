import React from 'react';
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa'; // Import the Instagram icon

import './Footer.css'; // Import CSS for styling

const Footer = () => {
  return (
    <footer className="footer-container">
      <p className="footer-name">Temporary Window</p>
      <p className="footer-social">
        <a href="https://instagram.com/temporarywindow" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://facebook.com/temporarywindow" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://youtube.com/temporarywindow" target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </a>
      </p>
      <p className="footer-email">
        <a href="mailto:contact@temporarywindow.com">contact@temporarywindow.com</a>
      </p>
    </footer>
  );
};

export default Footer;
