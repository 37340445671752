//HomePage at './pages/HomePage.js';

import React from 'react';
import GalleryIG from '../components/GalleryIG';
import Header from '../components/Header';
import Footer from '../components/Footer';


import './HomePage.css'; // Import the CSS file for styling


const HomePage = () => {
  return (
    <div className="homePageContainer">
      <Header />
      <div className="fullscreen-image">
      </div>
      <GalleryIG /> {/* Add InstagramGallery below other content */}
      <Footer />
    </div>
    
  );
};

export default HomePage;
