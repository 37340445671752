import React from 'react';
import './GalleryIG.css'; // Import CSS for styling

const GalleryIG = () => {
  // Sample data: array of image URLs (replace with your actual image URLs)
  const images = [
    '/images/thumbnails/KLM_TH.jpg',
    '/images/thumbnails/LH747_TH.jpg',
    '/images/thumbnails/AC777YVR_TH.jpg',
    '/images/thumbnails/AC787YYZ_TH.jpg',
    '/images/thumbnails/AC787HOUSE_TH.jpg'
  ];

  return (
    <div className="instagram-gallery-container">
      <h2 className="instagram-title">Instagram</h2>
      <div className="instagram-grid">
        {images.map((image, index) => (
          <div key={index} className="instagram-image-wrapper">
            <img src={image} alt={`Instagram ${index + 1}`} className="instagram-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryIG;
