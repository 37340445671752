//HomePage at './pages/HomePage.js';

import React from 'react';
import OtherHeader from '../components/OtherHeader'; // Make sure to import your Navbar component
import Footer from '../components/Footer';


import './StorePage.css';

const StorePage = () => {
  return (
    <div className="storePageContainer">

          <OtherHeader /> {/* Place Navbar on top of the image */}

      <div className="construction-image">
        <h1>I BUILD</h1>
      </div>
      <Footer />
    </div>

  );
};

export default StorePage;
